
import {
  defineComponent,
  PropType,
} from "vue";
import {
  Dictionary,
  DropdownComponent,
} from "table";
import { CALC_COMPONENTS } from "@/common/consts";
import DynamicComponents from "@/components/smart/DynamicComponents.vue";

export default defineComponent({
  name: "CalculatorsModals",
  components: {
    DynamicComponents,
    DropdownComponent,
  },
  props: {
    type: {
      type: String as PropType<"list" | "dropdown">,
      required: true,
    },
    mark: String,
  },
  emits: ["is-active-changed"],
  setup() {
    return {
      CALC_COMPONENTS,
    };
  },
  data() {
    return {
      show: {
        calcNameDict: {} as Dictionary<boolean>,
      },
    };
  },
  computed: {
    calcDropdownOptions() {
      return CALC_COMPONENTS.map((x) => ({
        title: x.title,
        onClick: () => {
          this.openCalc(x.component.name);
        },
      }));
    },
  },
  watch: {},
  created() {
  },
  methods: {
    onIsActiveChanged(props: any) {
      this.$emit("is-active-changed", props);
    },

    openCalc(calcName: string) {
      this.show.calcNameDict[calcName] = true;
    },

    hideCalc(calcName: string) {
      delete this.show.calcNameDict[calcName];
    },
  },
});
